"use client"; // Error components must be Client Components

import { useEffect } from "react";
import { Button } from "@nextui-org/button";
import Link from "next/link";

export default function Error({ error, reset }) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <div className="container min-h-screen text-center mt-20">
      <h1 className="text-xl mb-4">Something Went Wrong!</h1>
      <button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </button>
      <div className="mt-5">
        <p>or</p>
      </div>
      <div>
        <Button className="mt-5 bg-1B51CA text-white">
          <Link href="/">Return Home</Link>
        </Button>
      </div>
    </div>
  );
}
